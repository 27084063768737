/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useInView } from 'react-intersection-observer'

/**
 * Internal dependencies.
 */
import LottiePlayer from '../lottie-player';

import './style.scss';

/**
 * AnimatedImage Component.
 *
 * @todo Refactor component and take wrapper section out of it.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const AnimatedImage = ({ meta, device, className }) => {

	const lottieDevice = 'desktop' === device ? device : 'mobile';
	const { staticImage } = meta[lottieDevice];
	const [ref, inView] = useInView({
		threshold: 0.1,
		triggerOnce: true,
	});

	return (
		<section ref={ref} className={className} >
			<LottiePlayer
				className="lottie-animated-image"
				mobileJsonUrl={get(meta, 'mobile[lottie][mediaItemUrl]')}
				desktopJsonUrl={get(meta, 'desktop[lottie][mediaItemUrl]')}
				device={lottieDevice}
				loop={true}
				load={inView}
				staticImage={staticImage}
			/>
		</section>
	);
};

AnimatedImage.propTypes = {
	meta: PropTypes.object,
	device: PropTypes.string,
};

AnimatedImage.defaultProps = {
	meta: {
		desktop: {
			lottie: {
				mediaItemUrl: ''
			}
		},
		mobile: {
			lottie: {
				mediaItemUrl: ''
			}
		}
	},
	device: 'desktop',
	className: 'home-sections home-animated-image', // @todo Change default class names.
};

export default AnimatedImage;
