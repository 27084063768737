/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import { sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * Conversation-based Advertising Banner Component.
 *
 * @param {Object} page Component props.
 *
 * @return {jsx}
 */
const ConversationAdvertBanner = ( { page } ) => {

	const { title } = page;

	return (
		<section className="advertiser-sections conversation-advert-banner">
			<div className="row">
				<div className="small-12 columns">
					<h1 className="conversation-advert-banner__title" dangerouslySetInnerHTML={ { __html: sanitize( title ) } } />
				</div>
			</div>
		</section>
	);

};

ConversationAdvertBanner.propTypes = {
	page: PropTypes.object,
};

ConversationAdvertBanner.defaultProps = {
	page: {
		title: '',
	}
};

export default ConversationAdvertBanner;
