/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import { useDevice } from '../../hooks';
import Layout from '../../components/layout';
import ConversationAdvertBanner from '../../components/conversation-based-advertising/banner';
import ConversationAdvertContent from '../../components/conversation-based-advertising/content';
import AnimatedImage from '../../components/animated-image';
import ConversationListContent from '../../components/conversation-based-advertising/list-content';
import LogoGallery from '../../components/home/logo-gallery';
import ConversationAdvertForm from '../../components/conversation-based-advertising/form';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";
import FooterCTA from './../../components/footer-cta';

import './style.scss';

/**
 * Conversation Template Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const Conversation = (props) => {

	const {
		pageContext: {
			page,
			page: {
				conversationAdvertisingPageMeta: {
					contentSection,
					lottieAnimation,
					logoGallery,
					listsSectionHeadline,
					listsSection,
					formSection
				},
				seo,
				uri,
				footerCtaLinks: { footerCtaWrapper }
			}
		}
	} = props;

	const device = useDevice();

	const openGraphImage = getOgImage(seo);

	const FinalGalleryData = {
		title: `${logoGallery.title1} <span>${logoGallery.title2}</span>`,
		logos: logoGallery.logos
	};

	return (
		<Layout title={page.title} uri={uri} className="advertiser-pages conversation-based-advertising">
			<SEO
				title={page.title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<ConversationAdvertBanner page={page} />
			<ConversationAdvertContent meta={contentSection} tagName="QCP_Contact" tagParent="PUBLISHERS" />
			<AnimatedImage meta={lottieAnimation} device={device} />
			<ConversationListContent meta={listsSection} headline={listsSectionHeadline} />
			<LogoGallery meta={FinalGalleryData} />
			{footerCtaWrapper && <section className="conversation-footer__cta">
				<FooterCTA links={footerCtaWrapper} />
			</section>}
			<ConversationAdvertForm meta={formSection} tagName="QCP_Submit" tagParent="PUBLISHERS" />
		</Layout>
	)
};

Conversation.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default Conversation;
