/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

/**
 * Internal dependencies.
 */
import Link from './../../link';
import { sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * Conversation Advert List Content Component.
 *
 * @param {array} meta Component props.
 * @param {string} headline Section Headline.
 *
 * @return {jsx}
 */
const ConversationListContent = ( { meta, headline } ) => {

	return (
		<section className="advertiser-sections conversation-list">
			<div className="row">
				{ ! isEmpty ( headline ) ? (
						<div className="small-12 columns">
							<h2 className="conversation-list__section-headline" dangerouslySetInnerHTML={ { __html: sanitize( headline ) } } />
						</div>
				) : null }

				{ meta.map( ( data, index ) => (
					<div className="medium-6 small-12 columns conversation-list__item" key={ `list-${ index }` }>
						{ ! isEmpty ( headline ) ? (
							<h3 className="conversation-list__headline" dangerouslySetInnerHTML={ { __html: sanitize( data.headline ) } } />
						) : (
							<h2 className="conversation-list__headline" dangerouslySetInnerHTML={ { __html: sanitize( data.headline ) } } />
						) }
						<div className="conversation-list__description" dangerouslySetInnerHTML={ { __html: sanitize( data.description ) } } />
						<Link { ...data.ctaLink } withArrow={ true } />
					</div>
				) ) }
			</div>
		</section>
	);

};

ConversationListContent.propTypes = {
	meta: PropTypes.array,
};

ConversationListContent.defaultProps = {
	meta: [
		{
			headline: '',
			description: '',
			ctaLink: ''
		}
	]
};

export default ConversationListContent;
