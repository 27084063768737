/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import { sanitize } from '../../../utils/functions';
import Link from '../../link';

import './style.scss';

/**
 * Conversation Advert Content Component.
 *
 * @param {Object} meta Component props.
 *
 * @return {jsx}
 */
const ConversationAdvertContent = ( { meta, tagName, tagParent } ) => {

	const { content, ctaLink } = meta;

	return (
		<section className="advertiser-sections conversation-content">
			<div className="row conversation-content__row">
				<div className="medium-10 small-11 columns">
					<div className="conversation-content__text" dangerouslySetInnerHTML={ { __html: sanitize( content ) } } />
				</div>
				<div className="large-4 medium-6 small-12 columns">
					<Link { ...ctaLink } className="conversation-content__cta" tagName={ tagName } tagParent={ tagParent } />
				</div>
			</div>
		</section>
	);

};

ConversationAdvertContent.propTypes = {
	meta: PropTypes.object,
	tagName: PropTypes.string,
	tagParent: PropTypes.string,
};

ConversationAdvertContent.defaultProps = {
	meta: {
		content: '',
		ctaLink: ''
	},
	tagName: '',
	tagParent: ''
};

export default ConversationAdvertContent;
